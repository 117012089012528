<template>
<div>
	<!-- 添加弹窗 -->
	<el-dialog title="添加耗材档案" :visible.sync='dialogAddVisible'  width="50%" :close-on-click-modal="false" >
	    <el-form ref="form" :model="form" prop="" :rules="rules"  label-position="top">
			<el-row class="cse-file-content">
				<el-col :span="8"> 
					  <el-form-item label="物料编码" prop="mtl_code">
						 <el-input  v-model="form.mtl_code" :disabled="mtl_code_disabled" :placeholder="mtl_code_placeholder"></el-input>
					 </el-form-item>
				</el-col>
				<el-col :span="8"> 
					 <el-form-item label="物料名称" prop="name">
						 <el-input  v-model="form.name" ></el-input>
					 </el-form-item>
				 </el-col>
				 <el-col :span="8"> 
					<el-form-item label="物料分类" prop="cate_id">
						<el-select ref='treeSelect' v-model="form.cate_name" placeholder="请选择" clearable  @change="handleSelect" style="width: 100%;">
							<el-option value="0" label="无上级"></el-option>
							<el-tree node-key="id" :current-node-key="form.cate_id" :data="cateTreeData" :props="treePorps" highlight-current ref="tree"
								@node-click="(val)=>handleNodeClick(val)"> </el-tree>
						</el-select>
					</el-form-item>
				  </el-col>
				  <el-col :span="8">
					  <el-form-item label="商品条码" prop="bar_code">
					  		<el-input  v-model="form.bar_code"></el-input>
					  </el-form-item>
				   </el-col>
				   <el-col :span="8">
					   <el-form-item label="品牌" prop="brand">
					   		<el-input  v-model="form.brand"></el-input>
					   </el-form-item>
				    </el-col>
					<el-col :span="8">
						<el-form-item label="规格模型" prop="model">
							<el-input  v-model="form.model"></el-input>
						</el-form-item>
					 </el-col>
					<el-col :span="8">
						<el-form-item label="计量单位" prop="unit">
							<el-input  v-model="form.unit" ></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item  label="单价" prop="price" >
							<el-input-number style="width: 100%;" controls-position="right" v-model="form.price" :precision="2" :step="1" :max="9999999"  ></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="成本计算方法" prop="cost_calc_type">
							<el-select v-model="form.cost_calc_type" style="width: 100%;">
								<el-option :value="1" label="加权平均"></el-option>
								<el-option :value="2" label="批次管理"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="安全库存上限" prop="safe_stock_clg">
							<el-input-number style="width: 100%;" controls-position="right"   v-model="form.safe_stock_clg" :min="1"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="安全库存下限" prop="safe_stock_limit">
							<el-input-number  style="width: 100%;" controls-position="right"  v-model="form.safe_stock_limit" :min="1"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="限领数量(人/月)" prop="limit_number">
							<el-input-number style="width: 100%;" controls-position="right"   v-model="form.limit_number"  :step="1" :max="100"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="是否允许退库" prop="return_status">
							<template>
								 <el-radio v-model="form.return_status" :label="0">不允许</el-radio>
								 <el-radio v-model="form.return_status" :label="1">允许</el-radio>
							</template>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="备注" prop="remark">
							<el-input type="textarea" rows="5" v-model="form.remark"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						 <el-form-item label="物料照片" prop="remark">
							<el-upload
							 :action="$uploadUrl" 
							 list-type="picture-card"
							 :file-list="form.fileList"
							 :on-success="handleSuccessUploadImgs"
							 :on-remove="handleRemove"
							 :limit="6"
							 >
							 <i class="el-icon-plus"></i>
							<div slot="tip" class="el-upload__tip">最多能上传6张图片</div>
							</el-upload>
						 </el-form-item>
					</el-col>
		</el-row>
				
	    </el-form>
	     <div slot="footer" class="dialog-footer">
	       <el-button @click="dialogAddVisible = false">取 消</el-button>
	       <el-button type="primary" @click="confirm()">确 定</el-button>
	   </div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			dialogAddVisible:false,
			mtl_code_disabled:false,
			mtl_code_placeholder:'',
			form:{
			    id:'',
				cate_id:0,
				cate_name:'',
				bar_code:'',
				brand:'',
				model:'',
				unit:'',
				price:0.00,
				cost_calc_type:1,
				safe_stock_clg:0,
				safe_stock_limit:0,
				limit_number:0,
				picture:'',
				remark:'',
				return_status:1,
			},
			cateTreeData:[],
			rules:{
			      name: [
					{ required: true, message: '请输入物料名称', trigger: 'blur' },
					{ min: 1, max: 100, message: '长度在200个字符以内', trigger: 'blur' }
				  ],
				  cate_id: [
				  	{ required: true, message: '请选择物料分类', trigger: 'blur' },
				  ],
				  price: [
					{ required: true, message: '请输入物料单价', trigger: 'blur' },
				  ], 
				  cost_calc_type: [
				  	{ required: true, message: '请选择成本计算方法', trigger: 'blur' },
				  ],
			},
			treePorps:{
				label:'name'
			}
        }
    },
	watch: {
		'form.cate_id'() {
			this.$nextTick(() => {
				this.$refs.treeSelect.visible = false
			})
		},
	},
    created() {

    },
    mounted() {

    },
    methods:{
		handleAdd(id=0){
			if(id < 1){
				this.$api.get('CseFile/add',null,res=>{
				    if(res.code == 200){
						if(res.data.mtl_code_status == 1){
							this.mtl_code_disabled = true
							this.mtl_code_placeholder = '已开启自动编码'
						}
						this.$api.get('AssetCategory/treeData',{type:2}, res => {
							if (res.code == 200) {
								this.cateTreeData = res.data.tree_list
								
								this.dialogAddVisible = true
							}
						})
				       
				    }
				})
			}else{
				this.handleEdit(id)
			}
		},
		handleEdit(id){	//编辑数据
			this.$api.get('AssetCategory/treeData',{type:2}, res => {
				if (res.code == 200) {
					this.cateTreeData = res.data.tree_list
					this.$api.get('CseFile/edit',{'id':id},res=>{
						if (res.code == 200) {
							this.form =  res.data.info
							this.dialogAddVisible =  true
							this.$nextTick(function(){
								this.$refs.tree.setCurrentKey(this.form.cate_id)
							})
						}else{
							this.$message.error(res.msg)
						}
					})
				}
				
			})
		},
		handleBeforeUpload(file){
		    this.$emit('handleBeforeUpload',file)
		},
		handleSuccessUploadImgs(res,file,fileList){
			 let  len  = fileList.length
			 if(len > 6){		//最多只能上传9张图
				 this.$message.error('图片附件，最多只能上传6张')
				  return false;
			 }
		     fileList[fileList.length-1]['img_url'] = res.data.url
			 console.log('fileList:',fileList)
			 if(this.form.images == undefined || this.form.images.length < 1){
				 this.form.images = []
			 }
			 // this.form.images.push({url:res.data.url})
			 this.form.images.push(res.data.url)
		},
		handleRemove(file){		
		  let i=0
		  for(i;i<this.form.images.length;i++){
		      if(file.img_url == this.form.images[i]){
		          this.form.images.splice(i,1)
		      }
		  }
		},
		confirm(){
		     let url = 'CseFile/add'
		     if (this.form.id) {
		         url = 'CseFile/edit'
		     }  
		     this.$api.post(url,this.form,res=>{
		         if (res.code  == 200) {
		            this.dialogAddVisible =  false
		            this.$message.success(res.msg)
		            this.reload();   
		            this.$refs["form"].resetFields();
		            
		         }else{
		             this.$message.error(res.msg)
		         }
		     }) 
		},
		handleSelect(val){
			this.form.cate_id =  val
		},
		handleNodeClick(data){
			this.form.cate_name = data.name
			this.form.cate_id = data.id
		},
    },
}
</script>
<style  lang="scss">
	.cse-file-content{
		.el-col-8{ 
			padding-right: 10px;
		}
	}
</style>