<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
		耗材档案：创建档案后可向该档案导入相关耗材<i @click="question(9)"  class="el-icon-question" style="color:#909399;font-size: 16px;"></i>     
	 </div>  
	   <div class="search-form">
	     <el-form ref="form" :inline="true"  :model="search_key" prop="">
	         <el-form-item label="">
	             <el-input v-model="search_key.name" placeholder="输入关键字搜索"></el-input> 
	         </el-form-item>
	         <el-button type="primary" @click="getData()">查询</el-button>
			 <el-button type="primary" @click="handleExport()">导出全部</el-button>
	     </el-form>
	  </div>
	 <div style="margin-bottom:10px;" v-if="!batchStatus">
	      <el-button size="small" type="" @click="handleAdd()">添加</el-button>
	  </div> 
	  <div v-if="batchStatus" style="margin-bottom: 10px;">
			<el-button  class="btn"  size="small"  plain  @click="handleDel(multipleData)">删除</el-button>
			<el-button  class="btn"  size="small" plain  @click="handleExport(multipleSelection)">导出</el-button>
		</div> 
	  <el-table
	     :data="tableData" 
		 row-key="id"
		  @selection-change="handleSelectionChange"
	     style="width:100%">
		 <el-table-column
		 type="selection"
		 :reserve-selection="true">
		 </el-table-column>
	     <el-table-column
	         prop="mtl_code"
	         label="物料编码">
	     </el-table-column>
	      <el-table-column
	         prop="name"
	         label="物料名称">
	     </el-table-column>
	     <el-table-column 
	         prop="cate_name"
	         label="物料分类">
	     </el-table-column>
		 <el-table-column
		     prop="bar_code"
		     label="商品条码">
			 <template slot-scope="scope">
			 	<span>{{scope.row.bar_code?scope.row.bar_code:'-'}}</span>
			 </template>
		 </el-table-column>
		 <el-table-column
		     prop="brand"
		     label="品牌">
			 <template slot-scope="scope">
				 <span>{{scope.row.brand?scope.row.brand:'-'}}</span>
			 </template>
		 </el-table-column>
		 <el-table-column
		     prop="model"
		     label="规格型号">
			 <template slot-scope="scope">
			 		<span>{{scope.row.model?scope.row.model:'-'}}</span>
			 </template>
		 </el-table-column>
		 <el-table-column
		     prop="unit"
		     label="计量单位">
			 <template slot-scope="scope">
			 		<span>{{scope.row.unit?scope.row.unit:'-'}}</span>
			 </template>
		 </el-table-column>
		 <el-table-column
		     prop="safe_stock_clg"
		     label="安全库存上限">
		 </el-table-column>
		 <el-table-column
		     prop="safe_stock_limit"
		     label="安全库存下限">
		 </el-table-column>
		 <el-table-column
		     prop="return_status"
		     label="允许退库">
			 <template slot-scope="scope">
				 <span>{{scope.row.return_status==1?'允许':'不允许'}}</span>
			 </template>
		 </el-table-column>
		 <el-table-column
		     prop="price"
		     label="单价">
		 </el-table-column>
		 <el-table-column
			width="200px"
		     prop="create_time"
		     label="创建时间">
		 </el-table-column>
	     <el-table-column
			width="200px"
	         label="操作">
	         <template slot-scope="scope">
	             <el-button  size="small" @click="handleAdd(scope.row.id)">编辑</el-button>
	             <el-button  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
	         </template>
	     </el-table-column>
	  </el-table>
	  <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
	<AddVue ref="childDialog"></AddVue>
 </div>
</template>

<script>
import AddVue from './Add.vue'
export default {
	 inject:['reload'],
	components:{
	    AddVue,
	},
    data() {
        return {
			search_key:{
			    name:''
			},
			batchStatus:false,
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
			secret:'',
			multipleSelection:[]
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		
		question(){
			this.$refs.docDialog.getDoc(9)
		},
		getData(){  
		    this.$api.post('CseFile/index',{
		        page:this.currentPage,
		        limit:this.pagesize,
		        name:this.search_key.name
		    },res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
		             this.total =  res.data.count
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		
		handleAdd(id=0){
			this.$refs.childDialog.handleAdd(id)
		},
		handleDel(rows){
			var id = []
			if(rows instanceof Array){
				id  = this.multipleSelection
			}else{
				id.push(rows.id)
			}
		      this.$alert("确定删除该档案数据吗？", "删除提示", {
		         showConfirmButton: true,
		         showCancelButton: true,
		         callback: action => {
		             if (action == "confirm") {
		                 this.$api.delete("CseFile/del", { "id": id }, res => {
		                     if (res.code == 200) { 
		                         this.$message.success(res.msg);
		                         this.reload();
		                     }
		                 });
		             }
		             else if (action == "cancel") {
		                 this.reload();
		             }
		         }
		     });
		},
		//监听表格多选框
		handleSelectionChange(val){
		    this.multipleSelection  = []
		    var list = val;
		     list.forEach(item=>{
		         this.multipleSelection.push(item.id )
		     })
			if(this.multipleSelection.length > 0){
				this.batchStatus = true
			}else{
				this.batchStatus = false
			}
			
			console.log(this.multipleSelection)
		},
		  getCurrPage(val){   //获取当前页
		     this.currentPage =  val
		     this.getData()
		 },
		 getPageSize(val){   //获取每页条数
		     this.pagesize =  val
		     this.getData()
		 },
		handleExport(){	//导出
			var id = this.multipleSelection?this.multipleSelection.join(','):''
			location.href = '/api/admin/CseFile/export?id='+id
			
		}
    },
}
</script>
<style scoped>

</style>